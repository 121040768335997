import './LinkButton.scss';
import { getString } from '../../../helpers/styles';

export default function LinkButton({
  link,
  label,
  alternate,
  secondary,
  fixedWidth,
  transparent
}) {
  return (
    <a
      href={link}
      className={getString([
        {
          value: 'linkButton',
          condition: true
        },
        {
          value: 'linkButton-secondary',
          condition: secondary
        },
        {
          value: 'linkButton-transparent',
          condition: transparent
        },
        {
          value: 'linkButton-fixed-width',
          condition: fixedWidth
        },
        {
          value: 'linkButton-alternate',
          condition: alternate
        }
      ])}
    >
      {label}
    </a>
  );
}
