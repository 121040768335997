import Image from 'next/image';
import './IntroBox.scss';
import LinkButton from '../../LinkButton/LinkButton.jsx';
import ChevronRight from '../../../../shared/assets/icons/redesign/chevron-right.svg';

export default function IntroBox({ isMobile }) {
  const backgroundUrl = isMobile
    ? '/img/intro-mobile.webp'
    : '/img/intro-desktop.webp';

  return (
    <div className="introBox">
      <Image
        src={backgroundUrl}
        alt="Background"
        fill
        priority
        style={{ display: 'none' }}
      />

      <div
        className="introBox-main"
        style={{
          backgroundImage: `url('${backgroundUrl}')`,
          position: 'relative',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <div className="introBox-welcome-box">
          <h1 className="introBox-title">
            Dé start van een fijn en veilig avontuur
          </h1>
          <h4 className="introBox-subtitle">
            Afgestemd op je zwangerschap, bevalling, de babytijd en je partner
          </h4>

          <div className="introBox-links">
            <LinkButton link="dashboard" label="Zorg Dashboard" fixedWidth />
            <LinkButton
              link="over-zwangerenportaal"
              label="Meer informatie"
              secondary
              fixedWidth
            />
          </div>
        </div>
      </div>

      <div className="introBox-footer">
        <p className="introBox-footer-text">
          zwangerenportaal.nl wordt ondersteund door:
        </p>

        <div className="introBox-footer-content-group">
          <div>
            <span className="introBox-footer-count">250</span>
            <a
              className="introBox-footer-link-secondary"
              href="/organizations?code=000"
            >
              verloskundigenpraktijken
            </a>
          </div>

          <div>
            <span className="introBox-footer-count">2000</span>
            <a
              className="introBox-footer-link-secondary"
              href="/lokale-deskundigen"
            >
              Lokale deskundigen
            </a>
          </div>
        </div>

        <div className="introBox-footer-content-group">
          <p className="introBox-footer-text-small">
            Onze artikelen worden nageken door experts
          </p>

          <a
            href="/over-onze-medische-experts"
            className="introBox-footer-link"
          >
            Lees meer{' '}
            <ChevronRight className="introBox-footer-link-icon icon" />
          </a>
        </div>
      </div>
    </div>
  );
}
